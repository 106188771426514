<template>
  <div class="d-flex flex-column align-items-center">
    <div class="about-us-page">
      <div class="d-flex flex-md-row flex-column-reverse align-items-center justify-content-between mb-1">
        <h1 class="about-us-page-title">
          Om Norsk Helseportal
        </h1>
      </div>
      <div class="d-flex flex-md-row flex-column-reverse align-items-center justify-content-between mb-3">
        <h5>
          Norsk Helseportal skal utvide med nye tjenester og verktøy. På denne siden vil vi komme med oppdateringer og
          presentasjoner over hva som kommer på portalen.
        </h5>
      </div>
      <div class="main-content-block mb-5">
        <div class="d-flex info-row flex-column-reverse flex-md-row align-items-center justify-content-between">
          <div class="text-info-part col-md-6 px-0 d-flex pr-md-2 flex-column align-items-center mt-3 mt-md-0">
            <h2 class="mb-0">
              Hva er Norsk Helseportal?
            </h2>
            <span />
            <h5 class="mb-0 mt-1 mt-md-0">
              Lege Vegard von Wachenfeldt er CEO og grunder av Norsk Helseportal.
              von Wachenfeldt har vært fastlege, kommuneoverlege, overlege i Arbeids- og
              velferdsdirektoratet samt forsker. Fra sin erfaring så han at det meste av digital
              innovasjon var sentrert rundt pasient, men lite var tilpasset legen. Her forteller han om
              sin visjon for Norsk Helseportal bygd på hans erfaringer.
            </h5>
          </div>
          <div class=" col-md-6 px-0 pl-md-2" @mouseover="controls0 = 1" @mouseleave="controls0 = 0">
            <div class="videoWrapper">
              <div v-if="!playerActive['0']"
                class="video-player-actions d-flex align-items-center justify-content-center position-absolute">
                <img :src="playIcon" alt="play-btn" @click="play(0)">
              </div>
              <vue-vimeo-player ref="player-0" :options="options" :video-id="videoID1" @ready="onReady(0)" @pause="pause(0)"
                @ended="ended(0)" />
            </div>
          </div>
        </div>
        <div class="d-flex info-row flex-column flex-md-row align-items-center justify-content-between">
          <div class=" col-md-6 px-0 pr-md-2" @mouseover="controls1 = 1" @mouseleave="controls1 = 0">
            <div class="videoWrapper">
              <div v-if="!playerActive['1']"
                class="video-player-actions d-flex align-items-center justify-content-center position-absolute">
                <img :src="playIcon" alt="play-btn" @click="play(1)">
              </div>
              <vue-vimeo-player ref="player-1" :options="options" :video-id="videoID2" @ready="onReady(1)" @pause="pause(1)"
                @ended="ended(1)" />
            </div>
          </div>
          <div class="text-info-part col-md-6 pl-md-2 px-0 d-flex flex-column align-items-center mt-3 mt-md-0">
            <h2 class="mb-0">
              Grupper
            </h2>
            <span />
            <h5 class="mb-0 mt-1 mt-md-0">
              Grupper forenkler samhandling, oversiktlig og enkelt. Her har vi samlet en rekke verktøy, alt på ett sted.
              <br>
              <br>
              Dette er en tjeneste skreddersydd for:
              <ul class="pl-1">
                <li>• Foreninger</li>
                <li>• Internundervisning på sykehus</li>
                <li>• Veiledning for allmennmedisin, arbeidsmedisin og samfunnsmedisin</li>
                <li>• Små-grupper</li>
                <li>• Kommuneoverlegeside med informasjon til fastlegene i en kommune/bydel</li>
                <li>• Samhandling mellom fastleger og sykehus (PKO)</li>
              </ul>
            </h5>
          </div>
        </div>
        <div class="d-flex info-row flex-column-reverse flex-md-row align-items-center justify-content-between">
          <div class="text-info-part col-md-6 pr-md-2 px-0 d-flex flex-column align-items-center  mt-3 mt-md-0">
            <h2 class="mb-0">
              Forum
            </h2>
            <span />
            <h5 class="mb-0 mt-1 mt-md-0">
              Delta i diskusjoner tilpasset dine faglige interesser. Her kan du delta eller lage din egen forumside.
              <br/>
              <br/>
              I tillegg lager vi verktøy for:
              <ul class="pl-1">
                <li>
                  • å dele kasuistikker og diskutere pasienthistorier.
                </li>
                <li>• Har du et administrativt spørsmål du lurer på? Q er en tjeneste hvor du kan poste spørsmål og få svar av andre leger. Alle spørsmål og svar blir i etterkant søkbare.</li>
                <li>• Hva mener legene? Sett opp en poll hvor legene kan stemme anonymt. Resultatene blir presentert med demografisk statistikk og spesialiteter med fordelingen av stemmene.</li>
              </ul>
            </h5>
          </div>
          <div class=" col-md-6 px-0 pl-md-2" @mouseover="controls2 = 1" @mouseleave="controls2 = 0">
            <div class="videoWrapper">
              <div v-if="!playerActive['2']"
                class="video-player-actions d-flex align-items-center justify-content-center position-absolute">
                <img :src="playIcon" alt="play-btn" @click="play(2)">
              </div>
              <vue-vimeo-player ref="player-2" :options="options" :video-id="videoID3" @ready="onReady(2)" @pause="pause(2)"
                @ended="ended(2)" />
            </div>
          </div>
        </div>
        <div class="d-flex info-row flex-column flex-md-row align-items-center justify-content-between">
          <div class=" col-md-6 px-0 pr-md-2" @mouseover="controls3 = 1" @mouseleave="controls3 = 0">
            <div class="videoWrapper">
              <div v-if="!playerActive['3']"
                class="video-player-actions d-flex align-items-center justify-content-center position-absolute">
                <img :src="playIcon" alt="play-btn" @click="play(3)">
              </div>
              <vue-vimeo-player ref="player-3" :options="options" :video-id="videoID4" @ready="onReady(3)" @pause="pause(3)"
                @ended="ended(3)" />
            </div>
          </div>
          <div class="text-info-part col-md-6 pl-md-2 px-0 d-flex flex-column align-items-center  mt-3 mt-md-0">
            <h2 class="mb-0">
              Pages
            </h2>
            <span />
            <h5 class="mb-0 mt-1 mt-md-0">
              Er du en avtalespesialist eller representerer en klinikk, fagsenter, forening, legemiddelselskap eller en organisasjon?
              <br/>
              <br/>
              Sett opp en side på Norsk Helseportal som andre leger kan følge. Dine poster vil dukke opp på feeden til legene som følger siden. Alle sider er oversiktlig kategorisert og søkbare.
            </h5>
          </div>
        </div>
        <div class="d-flex info-row flex-column-reverse flex-md-row align-items-center justify-content-between">
          <div class="text-info-part col-md-6 pr-md-2 px-0 d-flex flex-column align-items-center  mt-3 mt-md-0">
            <h2 class="mb-0">
              Statistikk
            </h2>
            <span />
            <h5 class="mb-0 mt-1 mt-md-0">
              Har du lagd en page og lurer på hvem som leser postene dine?
              <br/>
              <br/>
              Vi har laget et avansert statistikkverktøy som gir deg informasjon over hvilke grupper som følger deg og leser postene dine. Optimaliser dine poster ved å sammenligne dine poster, og finn ut når det er mest optimalt å legge ut dine poster.
            </h5>
          </div>
          <div class=" col-md-6 px-0 pl-md-2" @mouseover="controls4 = 1" @mouseleave="controls4 = 0">
            <div class="videoWrapper">
              <div v-if="!playerActive['4']"
                class="video-player-actions d-flex align-items-center justify-content-center position-absolute">
                <img :src="playIcon" alt="play-btn" @click="play(4)">
              </div>
              <vue-vimeo-player ref="player-4" :options="options" :video-id="videoID5" @ready="onReady(4)" @pause="pause(4)"
                @ended="ended(4)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import playIcon from '@/assets/images/pages/home/play-icon.svg'
import { vueVimeoPlayer } from 'vue-vimeo-player'

export default {
  components: { vueVimeoPlayer },
  data() {
    return {
      playIcon,
      videoID1: '1011257468',
      videoID2: '1010880683',
      videoID3: '1010880650',
      videoID4: '1013127745',
      videoID5: '1010880722',
      options: {
        responsive: true,
        fullscreen: true,
        dnt: true,
        controls: true,
        interactive_markers: false,
        pip: false,
        play_button_position: 'None',
        quality_selector: false,
        title: false,
        transcript: false,
        vimeo_logo: false,
        watch_full_video: false,
        progress_bar: false,
        chromecast: false,
        airplay: false,
        byline: false,
        cc: false,
        playsinline: false,
        unmute_button: false,
      },
      playerReady: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
      },
      playerActive: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
      },
    }
  },
  methods: {
    onReady(index) {
      this.playerReady[index] = true
    },
    play(index) {
      if (this.playerReady[index]) {
        this.playerActive = {
          0: false,
          1: false,
          2: false,
          3: false,
          4: false,
        }
        this.$refs[`player-${index}`].play()
        this.playerActive[index] = true
      }
    },
    pause(index) {
      this.$refs[`player-${index}`].pause()
      const array = this.playerActive
      array[index] = false
      this.playerActive = array
    },
    ended(index) {
      this.playerActive[index] = false
    },
  },
}

</script>
